/* src/pages/Ranking.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Garantir que o contêiner ocupe a altura total da janela */
  background-color: #2e2e2e;
  color: #f0f0f0;
  padding-top: 100px; /* Ajuste conforme a altura da Navbar */
}

h1 {
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #e0e0e0;
}

table {
  width: 80%;
  max-width: 800px;
  border-collapse: collapse;
  background-color: #3c3c3c;
  border-radius: 8px;
  overflow: hidden;
}

th, td {
  padding: 12px 15px;
  text-align: center;
  color: #f0f0f0;
}

th {
  background-color: #444;
  font-size: 1.2rem;
}

td {
  background-color: #555;
  font-size: 1rem;
}

tr:nth-child(even) td {
  background-color: #666;
}

tr:hover td {
  background-color: #777;
}

tr:last-child td {
  border-bottom: none;
}

.reset-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #e60000;
}
